progress {
  appearance: none; /* Remove default styling */
}

progress::-webkit-progress-bar {
  background-color: #f3f3f3; /* Background color */
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #008080; /* Fill color */
  border-radius: 10px;
}

progress::-moz-progress-bar {
  background-color: #008080; /* Fill color */
  border-radius: 10px;
}
