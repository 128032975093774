.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.rightTopContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 5px;
}
.rightBottomContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px;
  column-gap: 10px;
  align-items: center;
}
