.topContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  padding-top: 60px;
  background: radial-gradient(circle at 50% 0%, #018585, transparent 50%),
    radial-gradient(circle at 100% 30%, #351f58d0, transparent 30%),
    radial-gradient(circle at 0% 50%, #351f58d0, transparent 30%);
}

.headerText {
  font-size: 42px;
  text-align: center;
  font-weight: 800;
}

.schedule {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 30vw;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 5vw;
}

.selectedInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 30px 0px 30px;
  background: rgba(0, 0, 0, 0.623);
  padding: 10px;
  width: 100%;
  /* Example of limiting width for better readability on larger screens */
}

.selectedInfo h2 {
  color: white;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.selectedInfo p {
  color: #000000a6;
  font-size: 18px;
  margin-bottom: 8px;
  text-align: center;
}
.dateTime {
  border-radius: 4px;
  background: linear-gradient(100deg, #008080 0%, #15b3b3 100%);
}
/* CSS */
.formContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 80vh;
  background-color: rgba(255, 255, 255, 0.3);
}

.formContainer img {
  border-radius: 0% !important;
  height: 80vh !important;
  width: 30% !important;
}

.forms {
  display: flex;
  padding: 10px;
  flex-direction: column;
  height: 100%; /* Adjust this line */
  width: 70%;
  justify-content: center;
  align-items: center;
}

.forms input[type="text"] {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  width: 70%;
  border-radius: 10px;
  color: black;
}
.forms input[type="password"] {
  background-color: transparent;
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  border: none;
  color: black;
}
.forms input[type="email"] {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  width: 70%;
  border-radius: 10px;
  color: black;
}

.forms button {
  width: 70%;
  border: 1px solid white;
  border-radius: 3px;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 70%;
}
.checkboxContainer input[type="checkbox"] {
  background-color: white;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.checkboxContainer input[type="checkbox"]:checked {
  background: linear-gradient(100deg, #008080 0%, #15b3b3 100%);
}
