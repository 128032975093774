.footerContainer {
  width: 100%;
  padding: 50px 72px 40px 72px;
  color: white;
  background: #13161f;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 64px;
 
}

.muted {
  color: #64748b;
}

.hover > a {
  text-decoration: none;
  color: #94a3b8;
}

.hover {
  padding: 4px;

  background-color: #0e1216;
  border-radius: 100px;
}

.hover:hover > a {
  color: white;
}

.footerContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 150px;
}

.footerNav {
  display: flex;
  /* grid-template-columns: repeat(5, 1fr); */
  /* gap: 40px; */
  gap: inherit;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .footerContent {
    grid-template-columns: repeat(1, 1fr);
    gap: 75px;
  }
  .footerNav {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .footerContainer {
    padding: 25px 36px 20px 36px;
    gap: 32px;
  }
}
