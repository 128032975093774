.topContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 10px;
}
.bottomContainer {
  display: flex;
  justify-content: space-around;
  margin: 15px 10px;
  align-items: center;
}
