.container {
  background-image: url("../assets/images/notfound.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.subContainer {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 200px;
  justify-content: center;
}
