body {
  font-family: "Plus Jakarta Sans", sans-serif;

  background-color: #018585;
}

.popular-plan {
  margin-bottom: 40px !important;
}

.popular-badge {
  background-color: #8dffbe; /* Light green background */
  color: #021313; /* Matching green text */
  text-align: center;
  font-weight: bold;
  font-size: 20px;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Full width */
  border-top: 2px solid white; /* Optional styling */
  border-radius: 0 0 10px 10px; /* Rounded corners */
}
