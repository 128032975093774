.mainContainer {
  display: grid;
  grid-template-columns: 25% 75%;
  height: 650px;
  background: linear-gradient(to right, #008080, #0e1118);
}
.rightContainer {
  margin: 15px;
  border: 2px white solid;
  border-radius: 5px;
}
img {
  border-radius: 0 !important;
}
