.card {
  width: 100%;
}
.card .card-button {
  position: absolute;
  top: 43%;
  box-shadow: none;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  background-color: transparent;
}
.card .card-button.right {
  right: 0%;
}
.react-stacked-center-carousel {
  /* padding: 20px 0; */
  overflow: hidden;
}

.card-card {
  box-sizing: border-box;
  transition: all 300ms ease;
  cursor: pointer;
  width: 100%;
  border-radius: 15px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.05);
}

.card-card:hover {
  transform: scale(1.05);
}

.react-stacked-center-carousel-slide-0 .card-card {
  cursor: default;
}

.react-stacked-center-carousel-slide-0 .card-card:hover {
  transform: none;
}

.fill {
  width: 100%;
  height: 100%;
}

.card-card .card-overlay {
  user-select: none;
  position: absolute;
  background-color: #00000099;
  transition: all 300ms ease;
  border-radius: 15px;
}

.card-card:hover .card-overlay {
  background-color: #0000002d;
}

.react-stacked-center-carousel-slide-0 .card-overlay {
  background-color: #00000099 !important;
}

.card-card .cover-image {
  object-fit: contain;
}

.card-card .cover {
  box-sizing: border-box;
  position: absolute;
  transition: opacity 300ms ease;
}
.react-stacked-center-carousel-slide-0 .card-card .cover {
  transition: opacity 300ms ease, z-index 0ms 300ms;
}

.card-card .cover.on {
  opacity: 1;
  z-index: 1;
}

.card-card .cover.off {
  opacity: 0;
  z-index: -1;
}

.card-card .detail {
  display: flex;
}
.card-card .video {
  width: 20%;
}
.card-card .discription {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
