.App {
  width: 100%;
  height: 100%;
  color: white;
  /* background: radial-gradient(circle at 50% 0%, #018585, transparent 60%),
    radial-gradient(circle at 100% 30%, #8a53e4d0, transparent 30%),
    radial-gradient(circle at 0% 50%, #351f58d0, transparent 30%),
    radial-gradient(circle at 0% 85%, #018585, transparent 20%); */
  background-color: #0e1118;
}

.container {
  display: flex;
}

.menuContainer {
  width: 250px;
  padding: 5px 20px;
  border-right: 2px solid;
}

.contentContainer {
  padding: 5px 20px;
  width: 100%;
}
