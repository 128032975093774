.topContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.rightBottomContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px;
  align-items: center;
}

.uploadButton {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.555);
  height: 40%;
  /* padding: 20px;
  margin: 40px; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid black;
}
.uploadButton img {
  border-radius: 0 !important;
  height: max-content;
  width: max-content;
}
.images {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: end;
  width: max-content;
}
.images img {
  border-radius: 0 !important;
  height: max-content !important;
  width: max-content !important;
  padding: 40px;
  transform: scale(1.5);
  cursor: pointer;
}
.images img:hover {
  transform: scale(1.7);
  transition: 0.5s;
}
.verticalLine {
  height: 200%;
  width: 3px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.719);
}
.device {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.555);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.errorBoundary {
  border: 2px solid;
  background-color: rgba(255, 0, 0, 0.205);
  transition: 0.5s;
}
/* UploadDoc.module.css */

.blur {
  filter: blur(5px);
  transition: filter 0.3s ease;
}
