.mainContainer {
  /* padding-top: 60px; */
  /* background: radial-gradient(circle at 50% 0%, #018585, transparent 20%),
    radial-gradient(circle at 100% 35%, #351f58d0, transparent 45%),
    radial-gradient(circle at 0% 50%, #351f58d0, transparent 20%),
    radial-gradient(circle at 0% 80%, #018585, transparent 30%),
    radial-gradient(circle at 100% 95%, #0e1118, transparent 10%); */
}

.topContainer {
  padding: 35px 150px;
  display: grid;
  grid-template-columns: 50% 50%;
}
.topContainerImage {
  width: 100%;
  height: 100% !important;
}
.secondtopcontainer {
  display: flex;
  margin: 35px 150px;
}

.CourtRoomBtn {
  border-radius: 5px;
  border: 3px solid white !important;
  background-color: transparent;
}

.courtRoomCard {
  margin: 5px;
  padding: 10px;
  background-image: linear-gradient(#008080, #018585);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
  border: 3px solid #0bc6c6;
  border-radius: 10px;
}

.whyCourtRoom {
  background: linear-gradient(90deg, #018585, #0e5156 40%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 80px;
}

.third {
  background-color: white;
  padding: 26px;
  align-items: center;
  border-radius: 15px;
  gap: 100px;
}

span {
  font-weight: bold;
  /* color: #ffffff; */
}
